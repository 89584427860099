const localRoute = [{
    "path": "/productManagement",
    "component": "Layout",
    "name": null,
    "meta": {"icon": "icon-qiyechanpinxinxi", "title": "产品管理"},
    "children": [{
        "path": "/productDetail",
        "component": "views/ProductManagement/ProductList/detail",
        "name": null,
        "meta": {"title": "产品配置"},
        "children": []
    }]
}, {
    "path": "/userManagement",
    "component": "Layout",
    "name": null,
    "meta": {"icon": "icon-userGroup2", "title": "用户管理"},
    "children": [{
        "path": "/userList",
        "component": "views/userManagement/userList/index",
        "name": null,
        "meta": {"title": "用户列表"},
        "children": []
    },{
        "path": "/decryptUser",
        "component": "views/userManagement/decryptUser/index",
        "name": null,
        "meta": {"title": "用户解密"},
        "children": []
    }]
}, {
    "path": "/orderManagement",
    "component": "Layout",
    "name": null,
    "meta": {"icon": "icon-dingdan", "title": "订单管理"},
    "children": [{
        "path": "/orderList",
        "component": "views/orderManagement/orderList/index",
        "name": null,
        "meta": {"title": "订单列表"},
        "children": []
    }, {
        "path": "/repayOrderList",
        "component": "views/orderManagement/repayOrderList/index",
        "name": null,
        "meta": {"title": "还款订单列表"},
        "children": []
    }, {
        "path": "/remitOrderList",
        "component": "views/orderManagement/remitOrderList/index",
        "name": null,
        "meta": {"title": "打款订单列表"},
        "children": []
    }]
}, {
    "path": "/smsManagement",
    "component": "Layout",
    "name": "",
    "meta": {"icon": "icon-duanxin", "title": "短信管理"},
    "children": [{
        "path": "/smsChannel",
        "component": "views/smsManagement/smsChannel/index",
        "name": null,
        "meta": {"title": "短信渠道"},
        "children": []
    }, {
        "path": "/smsRecord",
        "component": "views/smsManagement/smsRecord/index",
        "name": null,
        "meta": {"title": "短信记录"},
        "children": []
    }, {
        "path": "/smsTemplate",
        "component": "views/smsManagement/smsTemplate/index",
        "name": null,
        "meta": {"title": "短信模板"},
        "children": []
    }]
}, {
    "path": "/financialManagement",
    "component": "Layout",
    "name": "",
    "meta": {"icon": "icon-caiwu", "title": "财务管理"},
    "children": [{
        "path": "/balanceBill",
        "component": "views/financialManagement/balanceBill/index",
        "name": null,
        "meta": {"title": "财务平账"},
        "children": []
    },{
        "path": "/financialIndex",
        "component": "views/financialManagement/financialIndex/index",
        "name": null,
        "meta": {"title": "财务对账"},
        "children": []
    }, {
        "path": "/contract",
        "component": "views/financialManagement/contract/index",
        "name": null,
        "meta": {"title": "合同管理"},
        "children": []
    }]
}, {
    "path": "/systemSetting",
    "component": "Layout",
    "name": "",
    "meta": {"icon": "icon-shezhi", "title": "系统配置"},
    "children": [{
        "path": "/systemResource",
        "component": "views/systemSetting/systemResource/index",
        "name": null,
        "meta": {"title": "系统资源配置"},
        "children": []
    }, {
        "path": "/thirdResource",
        "component": "views/systemSetting/thirdResource/index",
        "name": null,
        "meta": {"title": "三方资源配置"},
        "children": []
    }, {
        "path": "/timerList",
        "component": "views/systemSetting/timerList/index",
        "name": null,
        "meta": {"title": "定时任务列表"},
        "children": []
    }]
}, {
    "path": "/logManagement",
    "component": "Layout",
    "name": "",
    "meta": {"icon": "icon-daily-fill", "title": "日志管理"},
    "children": [{
        "path": "/timerLog",
        "component": "views/logManagement/timerLog/index",
        "name": null,
        "meta": {"title": "定时任务日志"},
        "children": []
    }]
}, {
    "path": "/authManagement",
    "component": "Layout",
    "name": "",
    "meta": {"icon": "icon-authlock", "title": "权限管理"},
    "children": [
        {
            "path": "/account",
            "component": "views/AuthManagement/account/index",
            "name": "",
            "meta": {"icon": "", "title": "账号列表"},
            "children": []
        }
        // , {
        //     "path": "/menu",
        //     "component": "views/AuthManagement/menu/index",
        //     "name": null,
        //     "meta": {"title": "菜单列表"},
        //     "menuId": 2,
        //     "parentId": 1,
        //     "menuLevel": 2,
        //     "isParent": true,
        //     "children": []
        // }, {
        //     "path": "/role",
        //     "component": "views/AuthManagement/role/index",
        //     "name": null,
        //     "meta": {"title": "角色列表"},
        //     "menuId": 7,
        //     "parentId": 1,
        //     "menuLevel": 2,
        //     "isParent": true,
        //     "children": []
        // }
    ]
}
]


export default localRoute;
