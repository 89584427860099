/**
 * @Description:
 * @author Guan LinTao
 * @date 5:31 下午
 * 接口
 * http 封装好的Axios
 */

import http from '../utils/axios'

// 登录
export const login = prams => http('POST', '/login', prams)

// 获取系统用户列表
export const getSysUserList = prams => http('POST', '/console/user/getUserList', prams)

// 获取用户信息
export const getSysUserInfo = prams => http('POST', '/console/user/getUserInfo', prams)

// 添加用户
export const addSysUser = prams => http('POST', '/console/user/addUser', prams)

// 变更用户状态
export const changeSysUserStatus = prams => http('POST', '/console/user/changeUserStatus', prams)

// 修改用户名称
export const changeSysUserName = prams => http('POST', '/console/user/changeUserName', prams)

// 重置密码
export const resetPassword = prams => http('POST', '/console/user/resetPassword', prams)

// 删除用户
export const deleteUser = prams => http('POST', '/console/user/deleteUser', prams)

// 上传图片
export const uploadImage = prams => http('POST', '/console/common/uploadImage', prams)

