import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/antComponents'
import './utils/ElCom'
import 'ant-design-vue/dist/antd.css'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/global.scss'
import './permission'
import './utils/kgIcon'
import './utils/directive'
import { setWebSiteConfig,enumArrayObj,enumChannelObj} from './utils/tools'
Vue.config.productionTip = false;
Vue.prototype.$setWebSite = setWebSiteConfig;
Vue.prototype.$enumArrayObj = enumArrayObj;
Vue.prototype.$enumChannelObj = enumChannelObj;

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
