var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.readOnly)?_c('div',[(!_vm.border)?_c('img',{style:({
      width: `${_vm.customSize ? _vm.customSize.width : _vm.size}px`,
      height: `${_vm.customSize ? _vm.customSize.height : _vm.size}px`,
      borderRadius: _vm.border ? '50%' : '4px',
    }),attrs:{"src":_vm.value,"alt":""}}):(_vm.value)?_c('Avatar',{attrs:{"size":_vm.size,"src":_vm.value}}):_c('Avatar',{style:({
      background: 'rgb(33, 133, 240)',
      color: '#fff',
      fontWeight: 'bold',
      fontSize: `${_vm.fontSize}px`,
    }),attrs:{"size":_vm.size}},[_vm._v(" "+_vm._s(!_vm.value && _vm.avatarText ? _vm.avatarText.substr(0, 1) : "G")+" ")])],1):_c('div',{staticClass:"g-upload-img",style:({
    width: `${_vm.customSize ? _vm.customSize.width : _vm.size}px`,
    height: `${_vm.customSize ? _vm.customSize.height : _vm.size}px`,
    borderRadius: _vm.border ? '50%' : '4px',
  })},[(_vm.uploadLoad)?_c('a-spin',{staticClass:"uploader-loading"}):_vm._e(),_c('Upload',{attrs:{"name":"file","multiple":_vm.multiple,"custom-request":_vm.customUpload,"show-upload-list":false,"before-upload":_vm.beforeUpload,"accept":_vm.accept},on:{"change":_vm.uploadImg}},[(!_vm.uploadLoad)?_c('div',{staticClass:"upload-model"},[_c('GIcon',{attrs:{"icon":"icon-xiangji"}})],1):_vm._e(),(!_vm.border && _vm.value)?_c('img',{staticClass:"g-upload-square",style:({
        width: `${_vm.customSize ? _vm.customSize.width : _vm.size}px`,
        height: `${_vm.customSize ? _vm.customSize.height : _vm.size}px`,
      }),attrs:{"src":_vm.value,"alt":""}}):(!_vm.border && !_vm.value)?_c('span',{staticClass:"upload-text"},[_vm._v("点击上传")]):(_vm.border && _vm.value)?_c('Avatar',{attrs:{"size":_vm.size,"src":_vm.value}}):(_vm.border && !_vm.value)?_c('Avatar',{style:({
        background: 'rgb(33, 133, 240)',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: `${_vm.fontSize}px`,
      }),attrs:{"size":_vm.size}},[_vm._v(" "+_vm._s(!_vm.value && _vm.avatarText ? _vm.avatarText.substr(0, 1) : "G")+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }