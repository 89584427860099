/**
 * @Description:
 * @author Guan LinTao
 * @date 3:40 下午
 * 指令
 */
import Vue from 'vue'
import store from '@/store'

/**
 * 复制
 */
Vue.directive('copy', {
  bind: function (el, binding) {
    const copyValue = el.innerText
    el.innerHTML = `
      <div class="g-copy" id="GCopy">
        <span title=${copyValue} class='overflow-text'>${copyValue}</span>
        <svg class="icon" aria-hidden="true">
          <title>单击复制</title>
          <use xlink:href="#icon-fuzhiyemian" />
        </svg>
      </div>
    `
    el.addEventListener('click', function () {
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.setAttribute('value', binding.arg || copyValue)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      Vue.prototype.$message.success('复制成功')
      document.body.removeChild(input)
    })
  },
  unbind: function (el) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
})

// 注册一个全局自定义复制指令 `v-copy`
Vue.directive("copyVal", {
    bind(el, { value }) {
        el.$value = value;
        el.handler = () => {
            el.style.position = 'relative';
            if (!el.$value) {
                // 值为空的时候，给出提示
                Vue.prototype.$message.info('无复制内容')
                return
            }
            // 动态创建 textarea 标签
            const textarea = document.createElement('textarea');
            // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
            textarea.readOnly = 'readonly';
            textarea.style.position = 'absolute';
            textarea.style.top = '0px';
            textarea.style.left = '-9999px';
            textarea.style.zIndex = '-9999';
            // 将要 copy 的值赋给 textarea 标签的 value 属性
            textarea.value = el.$value
            // 将 textarea 插入到 el 中
            el.appendChild(textarea);
            // 兼容IOS 没有 select() 方法
            if (textarea.createTextRange) {
                textarea.select(); // 选中值并复制
            } else {
                textarea.setSelectionRange(0, el.$value.length);
                textarea.focus();
            }
            const result = document.execCommand('Copy');
            if (result) Vue.prototype.$message.success('复制成功')
            el.removeChild(textarea);
        }
        el.addEventListener('click', el.handler); // 绑定点击事件
    },
    // 当传进来的值更新的时候触发
    componentUpdated(el, { value }) {
        el.$value = value;
    },
    // 指令与元素解绑的时候，移除事件绑定
    unbind(el) {
        el.removeEventListener('click', el.handler);
    },
});


/**
 * 权限按钮
 * <div v-rights='1995'>编辑</>
 */
Vue.directive('rights', {
  inserted: function (el, binding) {
    const userRights = store.getters.userRights
    if (!userRights.get(binding.value)) {
      el.parentNode.removeChild(el)
    }
  }
})
