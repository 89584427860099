const configInfo = {
  // 当前用户已授权的菜单
  'authMenus': [],
  // 主页面左上角展示内容
  'app': {
    'appName': '泛米信用贷', // 左上角名称
    'iconUrl': '', // 左上角图标
    'linkUrl': '', // 点击左上角跳转地址
    'tabIcon': '', // 网页标题栏图标
    'tabTitle': '' // 网页标题
  }
}

export default configInfo
