/**
 * @Description:
 * @author Guan LinTao
 * @date 8:13 下午
 * 基础路由
 * 这里路由会直接被 vue-router加载 不需要额外的操作
 * 不需要权限管理
 */
import layout from '@/layouts/BasicLayout.vue'

export default [
    {
        path: '/',
        name: '/',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/pmsPage/404')
    },
    {
        path: '/403',
        name: 'exception',
        component: () => import('@/views/pmsPage/403')
    },
    {
        path: '/502',
        name: '502',
        component: () => import('@/views/pmsPage/502')
    },
    {
        path: '/detailCom',
        name: 'detailCom',
        component: layout,
        children: [
            {
                path: '/bannerDetail',
                name: 'bannerDetail',
                component: () => import('@/views/Operation/Banner/detail'),
                meta: {title: 'Banner详情', activeMenu: '/banner'}
            },
            {
                path: '/versionDetail',
                name: 'versionDetail',
                component: () => import('@/views/Operation/Version/detail'),
                meta: {title: '版本详情', activeMenu: '/version'}
            },
            {
                path: '/productDetail',
                name: 'productDetail',
                component: () => import('@/views/ProductManagement/ProductList/detail'),
                meta: {title: '产品详情', activeMenu: '/productDetail'}
            },
            {
                path: '/userDetail',
                name: 'userDetail',
                component: () => import('@/views/userManagement/userList/detail'),
                meta: {title: '用户详情', activeMenu: '/userDetail'}
            },
            {
                path: '/orderDetail',
                name: 'orderDetail',
                component: () => import('@/views/orderManagement/orderList/detail'),
                meta: {title: '订单详情', activeMenu: '/orderDetail'}
            },
            {
                path: '/filterOrder',
                name: 'filterOrder',
                component: () => import('@/views/orderManagement/orderList/filterOrder'),
                meta: {title: '筛选最近订单', activeMenu: '/filterOrder'}
            }, {
                path: '/repayAction',
                name: 'repayAction',
                component: () => import('@/views/orderManagement/orderList/repayAction'),
                meta: {title: '手动还款操作', activeMenu: '/repayAction'}
            },
            {
                path: '/financialDetail',
                name: 'financialDetail',
                component: () => import('@/views/financialManagement/financialIndex/detail'),
                meta: {title: '查看明细', activeMenu: '/financialDetail'}
            },
            {
                path: '/splitAccounts',
                name: 'splitAccounts',
                component: () => import('@/views/financialManagement/splitAccounts/index'),
                meta: {title: '分账设置', activeMenu: '/splitAccounts'}
            },
            {
                path: '/createBill',
                name: 'createBill',
                component: () => import('@/views/financialManagement/balanceBill/createBill'),
                meta: {title: '发起平账', activeMenu: '/createBill'}
            },
            {
                path: '/smsTemplateDetail',
                name: 'smsTemplateDetail',
                component: () => import('@/views/smsManagement/smsTemplate/detail'),
                meta: {title: '短信模板详情', activeMenu: '/smsTemplateDetail'}
            },
            {
                path: '/smsChannelDetail',
                name: 'smsChannelDetail',
                component: () => import('@/views/smsManagement/smsChannel/detail'),
                meta: {title: '短信渠道详情', activeMenu: '/smsChannelDetail'}
            },
            {
                path: '/systemResDetail',
                name: 'systemResDetail',
                component: () => import('@/views/systemSetting/systemResource/detail'),
                meta: {title: '系统资源配置详情', activeMenu: '/systemResDetail'}
            },
            {
                path: '/thirdResDetail',
                name: 'thirdResDetail',
                component: () => import('@/views/systemSetting/thirdResource/detail'),
                meta: {title: '三方资源配置详情', activeMenu: '/thirdResDetail'}
            },
            {
                path: '/timerDetail',
                name: 'timerDetail',
                component: () => import('@/views/systemSetting/timerList/detail'),
                meta: {title: '定时任务详情', activeMenu: '/timerDetail'}
            },

        ]
    }
]
