import router from './router'
import Cookies from 'js-cookie'
import store from './store'

// 页面白名单
const allowList = ['login', '404', 'exception', '502']

import Layout from '@/layouts/BasicLayout.vue'
router.beforeEach(async (to, from, next) => {
  // 当前访问页面是否需要登录
  if (allowList.includes(to.name)) {
    next()
  } else if (Cookies.get('userToken')) {
    /**
     * 当前是否前往登录
     * 已登录状态直接跳转不需要登录 前往配置的地址
     */
    // 用户信息
    // if (!store.getters.userInfo.userName) await store.dispatch('getUserInfo')

    // 登录状态下 无需再次登陆 跳转到默认页
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 用户菜单是否存在
      const hasRoles = store.getters.roles
      if (hasRoles) {
        // 路由是否存在
        if (to.name === '/') {
          next(store.getters.defaultPath)
        } else {
          next()
        }
      } else {
        try {
          // 获取菜单
          const responseRouters = await store.dispatch('getUserMenu');
          // 动态生成路由表
          let accessRoutes = await store.dispatch('permissionStore/generateRoutes', responseRouters)
          router.addRoute({
            path: '/rootRoute',
            name: 'rootRoute',
            component: Layout,
            children: accessRoutes.filter(v => v.meta.root)
          })
          // accessRoutes = accessRoutes.concat([{ path: '*', redirect: '/404' }])
          // const rootRoute = accessRoutes.filter(v => v.meta.root)
          // console.log(accessRoutes.filter(v => v.meta.root));
          accessRoutes.filter(v => !v.meta.root).forEach(v => {
            router.addRoute(v)
          })
          router.addRoute({ path: '*', redirect: '/404' })
          next({ ...to, replace: true })
        } catch (error) {
          next('/login')
        }
      }

    }
  } else {
    /**
     * 访问地址不在白名单
     * &&
     * 用户没有登录
     * 跳转到登录页
     */
    next('/login')
  }
})
